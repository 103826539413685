const ajax = function ({ url, method, data, successCallback, errorCallback }) {
    $.ajax({
        contentType: "application/json",
        dataType: "json",
        data,
        url,
        method,
        error: function (error) {
            errorCallback(error);
        },
        success: function (res) {
            successCallback(res);
        },
    })
}

$(document).ready(function() {
    ajax({
        url: `/car-makers`,
        method: 'GET',
        data: null,
        successCallback: function (res) {
            initCarMakers(res.carMakers);
        },
        errorCallback: function (res) {
            console.log(res);
        }
    })

    $('#header-car-maker-select').change(event => {
        const value = event.target.value;

        if (value == 'null') {
            populateSelect(
                '#header-model-select',
                [],
                'Model'
            );
        } else {
            ajax({
                url: `/models?carMaker=${value}`,
                method: 'GET',
                data: null,
                successCallback: function (res) {
                    populateSelect(
                        '#header-model-select',
                        res.models,
                        'Model'
                    )
                },
                errorCallback: function (res) {
                    console.log(res);
                }
            });
        }
    });

    $('#page-car-maker-select').change(event => {
        const value = event.target.value;

        if (value == 'null') {
            populateSelect(
                '#page-model-select',
                [],
                'Model'
            );
        } else {
            ajax({
                url: `/models?carMaker=${value}`,
                method: 'GET',
                data: null,
                successCallback: function (res) {
                    populateSelect(
                        '#page-model-select',
                        res.models,
                        'Model'
                    )
                },
                errorCallback: function (res) {
                    console.log(res);
                }
            });
        }
    });
});

const initCarMakers = async function (carMakers) {
    let select = $('.car-marker-select');

    carMakers.forEach(maker => {
        select.append(new Option(maker.name, maker.name));
    });

    if ($('.gear-box-type-hidden').length) {
        let data = $('.gear-box-type-hidden').data('gear-box-type');

        updateSelect('#page-gear-box-type-select', data);
        updateSelect('#header-gear-box-type-select', data);
    }

    if ($('.body-type-hidden').length) {
        let data = $('.body-type-hidden').data('body-type');

        updateSelect('#page-body-type-select', data);
        updateSelect('#header-body-type-select', data);
    }

    if ($('.fuel-type-hidden').length) {
        let data = $('.fuel-type-hidden').data('fuel-type');

        updateSelect('#page-fuel-type-select', data);
        updateSelect('#header-fuel-type-select', data);
    }

    if ($('.end-year-hidden').length) {
        let data = $('.end-year-hidden').data('end-year');

        updateSelect('#page-end-year-select', data);
        updateSelect('#header-end-year-select', data);
    }

    if ($('.begin-year-hidden').length) {
        let data = $('.begin-year-hidden').data('begin-year');

        updateSelect('#page-begin-year-select', data);
        updateSelect('#header-begin-year-select', data);
    }

    if ($('.car-maker-hidden').length) {
        let data = $('.car-maker-hidden').data('car-maker');

        // updateSelect('#page-car-maker-select', data);

        $('#page-car-maker-select').val(data);

        ajax({
            url: `/models?carMaker=${data}`,
            method: 'GET',
            data: null,
            successCallback: function (res) {
                populateSelect(
                    '#header-model-select',
                    res.models,
                    'Model'
                );

                populateSelect(
                    '#page-model-select',
                    res.models,
                    'Model'
                );

                if ($('.model-hidden').length) {
                    let modelData = $('.model-hidden').data('model');

                    console.log(modelData);

                    updateSelect('#page-model-select', modelData);
                    updateSelect('#header-model-select', modelData);
                }
            },
            errorCallback: function (res) {
                console.log(res);
            }
        });


        // if ($('.model-hidden').length) {

        // }
    }
}

const updateSelect = async function (select, value) {
    $(select).val(value).change();
}

const populateSelect = function (selectId, options, defaultOption) {
    let select = $(selectId);

    select.empty();
    select.append(new Option(defaultOption, null));

    options.forEach(option => {
        select.append(new Option (option.name, option.name))
    });
}

